import React, {ChangeEvent, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

import {routes} from "../routes";

import EuroSVG from "../assets/euro.svg";

import TitleLayout from "./TitleLayout";
import {Tenant, tenantFields, useTenants} from "../api/tenants";

const Calculator = () => {
  const history = useHistory();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [totalPoints, setTotalPoints] = useState<number>(0); //const [points, setPoints] = useState<string>("0");
  const [totals, setTotals] = useState<{price: number; points: number}>({
    price: 0,
    points: 0,
  });

  const [inputPrice, setInputPrice] = useState<string>("");
  const [tenant, setTenant] = useState<{
    tenantName: string;
    tenantId: string;
  }>();
  const {data: tenants} = useTenants();
  const [defaultValue, setDefaultValue] = useState<number>(0);
  //const defaultValue = tenants ? tenants.res[0].id : "";

  // const [formData, setFormData] = useState<{
  //   price: string;
  //   tenantId: string;
  //   tenantName: string;
  //   points: string;
  // }>({
  //   price: "",
  //   tenantId: "",
  //   points: "",
  //   tenantName: "",
  // });

  const [pointsData, setPointsData] = useState<
    {
      price: string;
      tenantId: string;
      tenantName: string;
      points: string;
    }[]
  >([]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!tenants || tenants.res.length === 0) return;

    const {value} = event.target;
    setInputPrice(value);

    if (!value || value === "0") {
      setPointsData(pointsData.slice(0, pointsData.length - 1));
      return;
    }
    if (pointsData.length === currentIndex) {
      setPointsData((prevState) => [
        ...prevState,
        {
          price: value,
          points: Math.round(Number(value) * 5).toString(),
          tenantId: tenant?.tenantId ?? tenants.res[0].id.toString(),
          tenantName: tenant?.tenantName ?? tenants.res[0].name,
        },
      ]);
    } else {
      setPointsData((prevState) => [
        ...prevState.slice(0, currentIndex),
        {
          ...prevState[currentIndex],
          price: value,
          points: Math.round(Number(value) * 5).toString(),
        },
      ]);
    }
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = event.target;
    const tenantName = event.target.selectedOptions[0].textContent || "";
    if (pointsData.length === currentIndex) {
      setTenant({tenantId: value, tenantName: tenantName});
    } else {
      setPointsData((prevState) => [
        ...prevState.slice(0, currentIndex),
        {
          ...prevState[currentIndex],
          tenantId: value,
          tenantName: tenantName,
        },
      ]);
    }
  };

  useEffect(() => {
    const totals = pointsData.reduce(
      (accumulators, currentValue) => {
        accumulators.points += Number(currentValue.points);
        accumulators.price += Number(currentValue.price);
        return accumulators;
      },
      {price: 0, points: 0}
    );
    setTotals({price: Number(totals.price.toFixed(2)), points: totals.points});
  }, [pointsData]);

  const deleteRow = (index: number) => {
    console.log(index);
    setInputPrice("");
    setCurrentIndex((prevState) => prevState - 1);
    setPointsData((prevState) => [
      ...prevState.slice(0, index),
      ...prevState.slice(index + 1, prevState.length),
    ]);
  };

  useEffect(() => {
    console.log(pointsData);
  }, [pointsData]);

  // useEffect(() => {
  //   if (tenants && tenants?.res.length > 0) {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       tenantId: tenants.res[0].id.toString(),
  //       tenantName: tenants.res[0].name,
  //     }));
  //   }
  // }, [tenants]);

  return (
    <TitleLayout title="Calculadora" showLogo={true}>
      <div className="flex flex-col pt-8  w-full p-8">
        <div className="text-center mb-12">
          <h1 className="uppercase font-semibold">Calculadora de puntos</h1>
        </div>
        <div className="w-full mb-1">
          <form className="w-full h-full" onSubmit={(e) => e.preventDefault()}>
            {/* <p>Selecciona la tienda:</p> */}
            <select
              className="w-full mb-4 px-2 py-4 border-torre-pink border-2"
              defaultValue={defaultValue}
              onChange={handleSelectChange}
              name="tenantId"
            >
              {/* <option value="0">Selecciona la tienda</option> */}
              {tenants &&
                tenants.res
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((tenant: Tenant) => (
                    <option value={tenant.id} key={tenant.id}>
                      {tenant.name}
                    </option>
                  ))}
            </select>
            <p>Introduce el valor del ticket:</p>
            <div className="w-full border border-black h-16 mt-2 mb-4 p-2 bg-white flex items-center">
              <input
                type="number"
                className="w-10/12 h-full border-r border-black"
                //onKeyDown={handleKeyDown}
                name="price"
                value={inputPrice}
                onChange={handleInputChange}
                min="0"
              ></input>

              <div className="flex flex-1 justify-center">
                <img src={EuroSVG} width={25} />
              </div>
            </div>
            <p className="mt-8">Valor en puntos:</p>{" "}
          </form>
        </div>
        <div className="">
          {pointsData.map((data, index) => (
            <div
              key={index}
              className={`${
                index !== pointsData.length - 1 ? "border-b border-black" : ""
              } py-2 flex justify-between items-center`}
            >
              <div className="w-1/2">
                <div className="">{data.tenantName}</div>
              </div>

              <div className="flex items-center w-1/2">
                <div className="w-1/2 flex justify-end">
                  <span className="">{`${data.price} €`}</span>
                </div>
                <div className="w-1/2 flex justify-end">
                  <span className="text-torre-pink font-semibold">
                    {data.points}
                  </span>
                  {currentIndex > index && (
                    <button
                      className="ml-2 bg-gray-400 rounded px-2"
                      onClick={() => deleteRow(index)}
                    >
                      x
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>{" "}
        {pointsData.length > 0 && (
          <>
            <div className="border-t border-black py-4 flex justify-between mb-4">
              <div className="flex w-1/2">
                <div className="text-lg font-semibold">TOTAL</div>
              </div>
              <div className="flex w-1/2">
                <div className="w-1/2 text-right">{`${totals.price} €`}</div>
                <div className="text-torre-pink font-semibold w-1/2 text-right">
                  {`${totals.points}`}
                </div>
              </div>
            </div>

            <button
              className="bg-torre-pink text-white px-4 py-2 rounded-lg uppercase"
              onClick={() => {
                setCurrentIndex((prevState) => prevState + 1);
                setInputPrice("");
              }}
            >
              Añadir más tickets
            </button>
          </>
        )}
        {/* <div className="text-torre-pink flex justify-center text-2xl font-semibold">
          {`${formData.points} puntos`}
        </div> */}
      </div>
      {/* {formData.points !== "0" && formData.tenantId !== "0" && ( */}
      {pointsData.length > 0 && (
        <>
          <div className=" flex flex-col justify-center items-center">
            <div className="text-sm font-semibold text-center px-5 md:px-8 pb-4">
              Escanea el QR del cliente para añadir los puntos
            </div>
            <button
              onClick={() =>
                history.push(`${routes.DASHBOARD}/${routes.USER_SCANNER}`, {
                  // points: formData.points,
                  // tenantId: formData.tenantId,
                  // tenantName: formData.tenantName,
                  pointsData: pointsData,
                })
              }
              className="w-full p-5 md:p-8 flex justify-center items-center uppercase md:text-xl font-black text-center bg-black text-white"
            >
              Escaner QR
              <svg
                className="ml-3 fill-current h-6 md:h-8"
                viewBox="0 0 512 512"
              >
                <path d="M0 0v170h170V0H0zm130 130H40V40h90v90z" />
                <path d="M65 65h40v40H65zM342 0v170h170V0H342zm130 130h-90V40h90v90z" />
                <path d="M407 65h40v40h-40zM0 342v170h170V342H0zm130 130H40v-90h90v90z" />
                <path d="M65 407h40v40H65zM40 197h40v40H40zM120 277v-40H80v40h39v40h40v-40zM280 77h40v40h-40zM200 40h40v77h-40zM240 0h40v40h-40zM240 117v40h-40v40h80v-80zM280 355v-39h-40v-79h-40v80h40v39h40v39h80v-40z" />
                <path d="M280 197h40v80h-40zM472 236v-39h-73v40h-39v40h40v39h112v-80h-40zm0 40h-72v-39h72v39zM472 355h40v80h-40zM320 277h40v40h-40zM360 395h40v40h-40zM400 355h40v40h-40zM400 435v77h40v-37h32v-40zM200 356h40v76h-40zM320 472v-40h-80v80h40v-40h39v40h40v-40zM120 197h80v40h-80zM0 237h40v80H0z" />
              </svg>
            </button>
          </div>
        </>
      )}
    </TitleLayout>
  );
};

export default Calculator;
