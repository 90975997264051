import React, {useEffect, useState} from "react";

import {useHistory, useLocation} from "react-router";
import {
  updateLoyaltyProductsDirectusUsers,
  useLoyaltyProduct,
} from "../api/product";
import {routes} from "../routes";

interface IQRUserPointsResult {
  eventID: string;
  eventName: string;
}

const QRProductResult = ({eventID, eventName}: IQRUserPointsResult) => {
  const history = useHistory();
  const location = useLocation<{code: string}>();
  const [showButton, setShowButton] = useState<boolean>(true);

  let productData: any = {};

  location.state.code.split(",").forEach((element) => {
    const [key, value] = element.split(":");
    const a = {[key]: value};
    productData = {...productData, ...a};
  });

  const {data: product} = useLoyaltyProduct(productData.loyalty_products_id);

  useEffect(() => {
    if (!showButton) {
      setTimeout(() => {
        history.replace(routes.DASHBOARD);
        history.go(-3);
      }, 1000);
    }
  }, [showButton]);

  return (
    <div className="bg-white">
      {/* <div className="border-b">
        <h1 className="p-4 text-lg font-semibold">
          <span className="uppercase mr-2">Evento:</span>
          {`${eventID} - ${eventName}`}
        </h1>
      </div> */}
      <div className="p-8 pb-16 w-full">
        {product !== undefined && (
          <>
            <h1 className="font-semibold uppercase text-center text-xl">
              Producto a entregar
            </h1>
            <div className="flex py-8 items-center">
              <div className="italic">{`${product?.points} puntos`}</div>
              <div className="ml-auto w-1/4">
                <img src={product?.brand.logo} />
              </div>
            </div>
            <div className="flex px-8 pb-8 justify-center">
              <img src={product?.imageUrl}></img>
            </div>
            <div className="font-semibold">{product?.title}</div>
            <div className="py-4 text-sm italic">{product?.description}</div>
          </>
        )}
      </div>
      <div className="absolute left-0 bottom-0 py-4 flex justify-center items-center w-full bg-white bg-opacity-75">
        {showButton ? (
          <button
            className="bg-black text-white px-4 py-2 rounded mx-auto "
            onClick={() => {
              updateLoyaltyProductsDirectusUsers(
                Number(productData.id),
                Number(eventID)
              )
                .then((res) => setShowButton(false))
                .catch((err) => console.log(err));
            }}
          >
            Finalizar entrega
          </button>
        ) : (
          <div className="bg-torre-pink px-2 py-1 text-white rounded mt-4">
            Producto Actualizado
          </div>
        )}
      </div>
    </div>
  );
};

export default QRProductResult;
