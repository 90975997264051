import React from "react";
import {Redirect, Route} from "react-router-dom";
import useStore from "../store";

interface PrivateRouteProps {
  path: string;
  redirectPath: string;
}

const ProtectedRoute: React.FC<PrivateRouteProps> = ({
  path,
  redirectPath,
  children,
}) => {
  const user = useStore((state) => state.user);
  //const user = {user: "user"};

  return (
    <Route
      path={path}
      render={({location}) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: {from: location},
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
