import React, {useState} from "react";
import TitleLayout from "../components/TitleLayout";
import FormTextField from "../components/FormTextField";
import BirthdayError from "../components/BirthdayError";
import BirthdayValidation from "../components/BirthdayValidation";
import Spinner from "../components/Spinner";

import {Formik, Form} from "formik";
import Button from "../components/Button";
import {checkBirthday, IUserBirtydayData} from "../api/user";
import {Switch, Route, useRouteMatch, useHistory} from "react-router-dom";
import {routes} from "../routes";

const Birthday = () => {
  const [errorForm, setErrorForm] = useState("");
  const [status, setStatus] = useState("waiting");
  const [errorBirthday, setErrorBirthday] = useState<string[]>([]);
  const [errorDays, setErrorDays] = useState(0);
  const [birthdateUser, setBirthdateUser] = useState<IUserBirtydayData>();

  const BIRTHDAY_RECEPTION_DAYS = 15;

  return (
    <TitleLayout title="Cumpleaños">
      <Formik
        initialValues={{email: ""}}
        onSubmit={async (values) => {
          setErrorBirthday([]);
          setErrorDays(0);
          setStatus("validating_user");
          const birthdatesData = await checkBirthday(values.email);

          if (birthdatesData.length === 0) {
            setStatus("error");
            setErrorForm("El usuario no existe");
          } else {
            const birthdateData = birthdatesData[0];
            setBirthdateUser(birthdateData);

            const birthdate = new Date(birthdateData.birthdate);
            const now = new Date();

            const thisYearBirthday = new Date(
              now.getFullYear(),
              birthdate.getMonth(),
              birthdate.getDate()
            );
            const today = new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate()
            );

            const MS_PER_DAY: number = 1000 * 60 * 60 * 24;
            const birthdateMs: number = thisYearBirthday.getTime();
            const todayMs: number = today.getTime();
            const daysBetweenDates: number = Math.ceil(
              (todayMs - birthdateMs) / MS_PER_DAY
            );

            let error = false;

            if (
              Number(birthdateData.lastBirtydayPresent) === now.getFullYear()
            ) {
              error = true;
              setStatus("error");
              setErrorBirthday(["El regalo ya se ha recogido."]);
            }

            if (
              daysBetweenDates < 0 ||
              daysBetweenDates > BIRTHDAY_RECEPTION_DAYS
            ) {
              error = true;
              setStatus("error");
              setErrorBirthday((e) => [
                ...e,
                "Fuera de la ventana de recepción.",
              ]);

              if (daysBetweenDates < 0) {
                setErrorDays(daysBetweenDates);
              }
              if (daysBetweenDates > BIRTHDAY_RECEPTION_DAYS) {
                setErrorDays(daysBetweenDates - BIRTHDAY_RECEPTION_DAYS);
              }
            }

            if (!error) {
              setStatus("validated_user");
            }
          }
        }}
      >
        <Form className="flex flex-col mt-6 mx-auto p-6 md:w-1/2">
          <FormTextField name="email" label={"Email"} type="email" />

          {errorForm && (
            <div className="uppercase text-sm font-black text-red-600">
              {errorForm}
            </div>
          )}

          <Button filled type="submit" className="mt-6 self-center">
            Comprobar
          </Button>
        </Form>
      </Formik>
      {status === "validating_user" ? (
        <Spinner />
      ) : status === "validated_user" && birthdateUser ? (
        <BirthdayValidation
          userID={birthdateUser?.id}
          year={new Date().getFullYear().toString()}
        />
      ) : (
        <BirthdayError msg={errorBirthday} days={errorDays} />
      )}
    </TitleLayout>
  );
};
export default Birthday;
