import useStore from "../store";

export const apiBase = "https://cms.latorreoutletzaragoza.com/_";
//export const apiBase = "http://localhost:1338/_";

async function dataFetcher(endpoint: string) {
  const token = useStore.getState().token;
  const res = await fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
  });
  const {meta, data} = await res.json();
  return {meta, data};
}

export async function dataProcessor<T>(
  endpoint: string,
  processor: (data: any) => T
): Promise<T> {
  const {data} = await dataFetcher(endpoint);
  //console.log(data);
  return processor(data);
}

export async function multiDataProcessor<T>(
  endpoint: string,
  processor: (data: any) => T
): Promise<{meta: {total_count: number}; res: T[]}> {
  let {meta, data} = await dataFetcher(endpoint);
  if (!Array.isArray(data)) data = [data];
  return {meta, res: data.map(processor)};
}
