import React from "react";
import {routes} from "../routes";
import Feature from "../components/Feature";
import logo from "../assets/logo.png";

const Features = () => {
  return (
    <div className="p-20 flex flex-col flex-grow justify-center items-center">
      <img
        className="w-24 mx-auto mt-8 absolute top-0"
        src={logo}
        alt="La Torre Outlet Zaragoza"
      />
      <Feature name="Programa de fidelización" route={routes.LOYALTYSELECTOR} />
      <Feature name="Eventos" route={routes.EVENTS} />
      <Feature name="Cumpleaños" route={routes.BIRTHDAY} />
    </div>
  );
};

export default Features;
