import React from "react";
import {routes} from "../routes";
import Feature from "../components/Feature";
import TitleLayout from "./TitleLayout";

const LoyaltySelector = () => {
  return (
    <TitleLayout title="" showLogo>
      <div className="p-20 flex flex-col flex-grow justify-center items-center">
        <Feature name="Calculadora de puntos" route={routes.CALCULATOR} />
        <Feature name="Validación artículo" route={routes.PRODUCT_SCANNER} />
      </div>
    </TitleLayout>
  );
};

export default LoyaltySelector;
