import React from "react";
import {Link} from "react-router-dom";
import {routes} from "../routes";

interface FeatureInterface {
  name: string;
  route: string;
}

const Feature: React.FC<FeatureInterface> = ({name, route}) => {
  return (
    <Link
      className="w-full p-4 my-2 border-black border-2 uppercase text-center"
      to={`${routes.DASHBOARD}/${route}`}
    >
      {name}
    </Link>
  );
};

export default Feature;
