import {useQuery} from "react-query";
import useStore from "../store";
import {apiBase, dataProcessor, multiDataProcessor} from "./utils";

export interface LoyaltyProduct {
  id: number;
  nameid: string;
  points: number;
  stock: number;
  from: string;
  to: string;
  imageUrl: string;
  title: string;
  description: string;
  brand: {
    id: number;
    slug: string;
    name: string;
    logo: string;
  };
}

export const loyaltyFields = [
  "id",
  "nameid",
  "points",
  "from",
  "stock",
  "to",
  "image.data",
  "translations.title",
  "translations.description",
  "brand.id",
  "brand.slug",
  "brand.name",
  "brand.logo.data",
].join(",");

function processLoyalty(data: any): LoyaltyProduct {
  //console.log(data);
  return {
    id: data.id,
    nameid: data.nameid,
    points: data.points,
    stock: data.stock,
    from: data.from,
    to: data.to,
    imageUrl: data.image.data.full_url,
    title: data.translations[0].title ?? "",
    description: data.translations[0].description ?? "",
    brand: {
      id: data.brand?.id,
      slug: data.brand?.slug,
      name: data.brand?.name,
      logo: data.brand?.logo.data.full_url,
    },
  };
}

function dateTimeToMySql() {
  let date = new Date();
  return date.toISOString().slice(0, 19).replace("T", " ");
}

export function useLoyaltyProduct(id: string) {
  const endpoint = `${apiBase}/items/loyalty_products/${id}?lang=es&fields=${loyaltyFields}`;
  return useQuery(["product", id], () =>
    dataProcessor(endpoint, processLoyalty)
  );
}

export async function updateLoyaltyProductsDirectusUsers(
  id: number,
  eventID: number
) {
  try {
    const token = useStore.getState().token;

    const res = await fetch(
      `${apiBase}/items/loyalty_products_directus_users/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          status: 1,
          //updated_on: dateTimeToMySql(),
          event: eventID,
        }),
      }
    );

    if (!res.ok) {
      throw new Error();
    }

    const jsonRes = await res.json();

    return jsonRes.data;
  } catch (err) {
    throw new Error();
  }
}
