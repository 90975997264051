import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import QRResult from "../components/QRResult";
import QRScanner from "../components/QRScanner";
import TitleLayout from "../components/TitleLayout";
import {routes} from "../routes";

const Scanner = () => {
  let {path} = useRouteMatch();

  return (
    <TitleLayout title="Escaner QR">
      <Router>
        <Switch>
          <Route exact path={path}>
            <QRScanner type="scanner" />
          </Route>

          <Route path={`${path}/${routes.SCANNER_RESULT}`}>
            <QRResult />
          </Route>
        </Switch>
      </Router>
    </TitleLayout>
  );
};

export default Scanner;
