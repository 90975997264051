import {Form, Formik} from "formik";
import React, {useState} from "react";
import {useHistory, Link} from "react-router-dom";
import {refreshToken, signIn} from "../api/auth";
import logo from "../assets/logo.png";
import Button from "../components/Button";
import FormTextField from "../components/FormTextField";
import {routes} from "../routes";
import useStore from "../store";

const Home = () => {
  const history = useHistory();
  const [setUser, setToken] = useStore((state) => [
    state.setUser,
    state.setToken,
  ]);
  const user = useStore((state) => state.user);
  const [error, setError] = useState("");

  /*
   1: Admin
   8: Staff
  */
  const allowedRoleIds = ["1", "8"];

  return (
    <div className="m-auto w-full">
      <img
        className="w-40 md:w-56 mx-auto"
        src={logo}
        alt="La Torre Outlet Zaragoza"
      />

      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={async (values) => {
          try {
            const userData = await signIn(values.email, values.password);
            if (!allowedRoleIds.includes(userData.user.role)) {
              setError("User not allowed");
            } else {
              setUser(userData.user);
              setToken(userData.token);
              // history.push(`${routes.DASHBOARD}/${routes.EVENTS}`);
              history.push(`${routes.DASHBOARD}`);

              // Refresh token periodically
              setInterval(async () => {
                console.log("refreshing token...");
                const token = await refreshToken();
                setToken(token);
              }, 5 * 60 * 1000);
            }
          } catch (e) {
            setError("Incorrect user/password");
          }
        }}
      >
        <Form className="mt-6 mx-auto p-6 md:w-1/2">
          <FormTextField name="email" label={"Email"} type="email" />

          <FormTextField name="password" label={"Password"} type="password" />

          {error && (
            <div className="uppercase text-sm font-black text-red-600">
              {error}
            </div>
          )}

          <Button filled type="submit" className="mt-6">
            Continue
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

export default Home;
