import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import {routes} from "../routes";
import Events from "./Events";
import Scanner from "./Scanner";
import Birthday from "./Birthday";
import Features from "../components/Features";
import LoyaltySelector from "../components/LoyaltySelector";
import Calculator from "../components/Calculator";
import UserPointsScanner from "./UserPointsScanner";
import ProductScanner from "./ProductScanner";

const Dashboard = () => {
  let {path} = useRouteMatch();

  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/${routes.MAIN}`} />
        </Route>

        <Route path={`${path}/${routes.MAIN}`}>
          <Features />
        </Route>

        <Route path={`${path}/${routes.EVENTS}`}>
          <Events />
        </Route>

        <Route path={`${path}/${routes.SCANNER}`}>
          <Scanner />
        </Route>
        <Route path={`${path}/${routes.USER_SCANNER}`}>
          <UserPointsScanner />
        </Route>

        <Route path={`${path}/${routes.PRODUCT_SCANNER}`}>
          <ProductScanner />
        </Route>

        <Route path={`${path}/${routes.BIRTHDAY}`}>
          <Birthday />
        </Route>

        <Route path={`${path}/${routes.LOYALTYSELECTOR}`}>
          <LoyaltySelector />
        </Route>

        <Route path={`${path}/${routes.CALCULATOR}`}>
          <Calculator />
        </Route>
      </Switch>
    </Router>
  );
};

export default Dashboard;
