import jsQR from "jsqr";
import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {routes} from "../routes";

interface IQRScannerProps {
  type: "scanner" | "product" | "user";
}

const QRScanner = ({type}: IQRScannerProps) => {
  const {path} = useRouteMatch();
  const history = useHistory();
  const video = useMemo(() => {
    const v = document.createElement("video");
    v.muted = true;
    v.setAttribute("playsinline", "playsinline");
    return v;
  }, []);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const requestRef = useRef(0);

  const tick = useCallback(() => {
    if (canvasRef.current) {
      canvasRef.current.width = video.videoWidth || 1;
      canvasRef.current.height = video.videoHeight || 1;
      const ctx = canvasRef.current.getContext("2d")!;

      ctx.drawImage(
        video,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );

      const imageData = ctx.getImageData(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );

      const code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: "dontInvert",
      });
      //console.log(code);
      if (code) {
        switch (type) {
          case "scanner":
            history.push(`${path}/${routes.SCANNER_RESULT}`, {code: code.data});
            break;
          case "product":
            history.push(`${path}/${routes.PRODUCT_SCANNER_RESULT}`, {
              code: code.data,
            });
            break;
          case "user":
            history.push(`${path}/${routes.USER_SCANNER_RESULT}`, {
              code: code.data,
            });
            break;
        }
      }
    }

    requestRef.current = requestAnimationFrame(tick);
  }, [video]);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({
        video: {facingMode: "environment", width: 350},
        audio: false,
      })
      .then((stream) => {
        video.srcObject = stream;
        video.play();
        requestRef.current = requestAnimationFrame(tick);
      })
      .catch(console.log);

    return () => cancelAnimationFrame(requestRef.current);
  }, [tick, video]);

  return <canvas className="w-full m-auto" ref={canvasRef} />;
};

export default QRScanner;
