import React from "react";

interface ButtonProps {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  filled?: boolean;
  pink?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  className,
  onClick,
  filled,
  pink,
  type,
  disabled,
  children,
}) => {
  const baseCss = `block px-4 py-3 text-center uppercase tracking-widest ${
    className || ""
  } `;
  const bgColor = pink ? "bg-torre-pink" : "bg-black";
  const filledCss = bgColor + " text-white hover:bg-torre";
  const nonFilledCss = "border-2 border-black";
  const finalCss = filled
    ? `${baseCss} ${filledCss}`
    : `${baseCss} ${nonFilledCss}`;

  return (
    <button
      className={finalCss}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
