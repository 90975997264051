import create from "zustand";
import { IUserData } from "./api/auth";

interface IStore {
  user: IUserData | null;
  setUser: (user: IUserData) => void;
  token: string;
  setToken: (token: string) => void;
}

const useStore = create<IStore>((set) => ({
  user: null,
  setUser: (user: IUserData) => set({ user }),
  token: "",
  setToken: (token: string) => set({ token }),
}));

export default useStore;
