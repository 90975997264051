import { usePaginatedQuery, useQuery } from "react-query";
import useStore from "../store";
import { TorreEvent } from "./events";
import { apiBase, multiDataProcessor } from "./utils";

export interface TorreEventReservation {
  id: number;
  owner: { name: string; lastName: string };
  created_on: string;
  event: {
    id: number;
    from: string;
    to: string;
    name: string;
    description: string;
    squareBanner: string;
  };
  used: boolean;
}

const eventReservationsFields = [
  "id",
  "owner.first_name",
  "owner.last_name",
  "event.id",
  "event.from",
  "event.to",
  "event.translations.name",
  "event.translations.description",
  "event.square_banner.data",
  "used",
].join(",");

function processEventReservation(data: any): TorreEventReservation {
  return {
    id: data.id,
    owner: {
      name: data.owner.first_name,
      lastName: data.owner.last_name,
    },
    created_on: data.created_on,
    event: {
      id: data.event.id,
      from: data.event.from,
      to: data.event.to,
      name: data.event.translations[0].name,
      description: data.event.translations[0].description,
      squareBanner: data.event.square_banner.data.full_url,
    },
    used: data.used,
  };
}

export function useEventReservations(
  event: TorreEvent | undefined,
  itemsPerPage: number,
  page: number
) {
  const endpoint = `${apiBase}/items/event_reservations?lang=es&filter[event]=${event?.id}&fields=${eventReservationsFields}&limit=${itemsPerPage}&page=${page}&meta=total_count`;

  return usePaginatedQuery(
    ["event_reservations", page, event?.id],
    () => multiDataProcessor(endpoint, processEventReservation),
    { enabled: event }
  );
}

export function useEventReservationByCode(code: string) {
  const endpoint = `${apiBase}/items/event_reservations?lang=es&filter[qr_code]=${code}&fields=${eventReservationsFields}`;
  return useQuery(["event_reservation", code], () =>
    multiDataProcessor(endpoint, processEventReservation)
  );
}

export async function confirmEventReservation(id: number) {
  const token = useStore.getState().token;

  let res = await fetch(`${apiBase}/items/event_reservations/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify({ used: 1 }),
  });

  return await res.json();
}
