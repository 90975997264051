import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import QRScanner from "../components/QRScanner";
import QRUserPointsResult from "../components/QRUserPointsResult";
import TitleLayout from "../components/TitleLayout";
import {routes} from "../routes";
import Dashboard from "./Dashboard";

const UserPointsScanner = () => {
  let {path} = useRouteMatch();
  const location = useLocation<{
    // points: string;
    // tenantId: string;
    // tenantName: string;
    pointsData: {
      price: string;
      tenantId: string;
      tenantName: string;
      points: string;
    }[];
  }>();
  // const points = location.state.points;
  // const tenantId = location.state.tenantId;
  // const tenantName = location.state.tenantName;
  const pointsData = location.state.pointsData;
  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <TitleLayout title="Escaner QR">
            <QRScanner type="user" />{" "}
          </TitleLayout>
        </Route>

        <Route path={`${path}/${routes.USER_SCANNER_RESULT}`}>
          <TitleLayout title="Añadiendo puntos" showLogo>
            <QRUserPointsResult
              // points={points}
              // tenantId={tenantId}
              // tenantName={tenantName}
              pointsData={pointsData}
            />
          </TitleLayout>
        </Route>
      </Switch>
    </Router>
  );
};

export default UserPointsScanner;
