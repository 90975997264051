export const routes = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  MAIN: "main",
  EVENTS: "events",
  SCANNER: "scanner",
  SCANNER_RESULT: "result",
  USER_SCANNER: "user_scanner",
  USER_SCANNER_RESULT: "user_scanner_result",
  PRODUCT_SCANNER: "product_scanner",
  PRODUCT_SCANNER_RESULT: "product_scanner_result",
  BIRTHDAY: "birthday",
  ERROR: "error",
  VALIDATED: "validated",
  LOYALTY: "loyalty",
  LOYALTYSELECTOR: "loyaltyselector",
  CALCULATOR: "calculator",
};
