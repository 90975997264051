import React, {useState} from "react";
import Spinner from "./Spinner";
import Button from "./Button";
import {updateLastBirthdayPresent} from "../api/user";

interface BirthdayValidationProps {
  userID: string;
  year: string;
}

const giveGift = (id: string, year: string) => {};

const BirthdayValidation: React.FC<BirthdayValidationProps> = ({
  userID,
  year,
}) => {
  const [state, setState] = useState("");

  return (
    <div className="p-6 flex flex-col justify-center text-center">
      {state === "validating" ? (
        <Spinner />
      ) : state === "validated" ? (
        <div>La entrega del regalo se ha registrado correctamente.</div>
      ) : (
        <>
          <p>Este usuario todavía no ha recogido su regalo</p>
          <Button
            className="m-6"
            onClick={async () => {
              const res = await updateLastBirthdayPresent(userID, year);
              setState("validated");
            }}
          >
            Entregar regalo
          </Button>
        </>
      )}
    </div>
  );
};

export default BirthdayValidation;
