import useStore from "../store";

const apiBase = "https://cms.latorreoutletzaragoza.com/_";
//const apiBase = "http://localhost:1338/_";

export interface IUserData {
  first_name: string;
  email: string;
  role: string;
}

interface IUser {
  token: string;
  user: IUserData;
}

export async function signIn(email: string, password: string): Promise<IUser> {
  const res = await fetch(`${apiBase}/auth/authenticate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({email, password}),
  });

  const jsonRes = await res.json();

  return jsonRes.data;
}

export async function refreshToken(): Promise<string> {
  const token = useStore.getState().token;

  const res = await fetch(`${apiBase}/auth/refresh`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({token}),
  });

  const jsonRes = await res.json();

  return jsonRes.data.token;
}
