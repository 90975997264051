import React from "react";
import Modal from "react-modal";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import {routes} from "./routes";
import Dashboard from "./routes/Dashboard";
import Home from "./routes/Home";

Modal.setAppElement("#root");

function App() {
  return (
    <div className="absolute inset-0 flex flex-col">
      <Router>
        <Switch>
          <Route exact path={routes.HOME}>
            <Home />
          </Route>

          <ProtectedRoute path={routes.DASHBOARD} redirectPath={routes.HOME}>
            <Dashboard />
          </ProtectedRoute>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
