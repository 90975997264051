import React from "react";
import {Link} from "react-router-dom";
import {TorreEvent} from "../api/events";
import {routes} from "../routes";
import TitleLayout from "./TitleLayout";

interface EventListProps {
  events: TorreEvent[];
}

const EventList: React.FC<EventListProps> = ({events}) => {
  return (
    <TitleLayout title="Eventos">
      <div className="p-6 pb-24 grid grid-cols-1 md:grid-cols-2 gap-6">
        {events &&
          events.map((e) => (
            <Link
              className="block"
              key={e.id}
              to={`${routes.DASHBOARD}/${routes.EVENTS}/${e.id}`}
            >
              <div className="rounded leading-none bg-white shadow-lg">
                <img
                  className="rounded-t w-full h-64 object-cover"
                  src={e.squareBanner}
                  alt={e.name}
                />

                <div className="p-4">
                  <h2 className="font-bold uppercase text-xl">{e.name}</h2>

                  <div className="mt-3 flex justify-between">
                    <p>
                      Capacidad:{" "}
                      <span className="font-black">{e.capacity}</span>
                    </p>
                    <p>
                      Reservas:{" "}
                      <span className="font-black">{e.reservations}</span>
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          ))}
      </div>
    </TitleLayout>
  );
};

export default EventList;
