import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {addLoyaltyPoints, updateUserPoints} from "../api/user";
import {routes} from "../routes";

interface IQRUserPointsResult {
  // points: string;
  // tenantId: string;
  // tenantName: string;
  pointsData: {
    price: string;
    tenantId: string;
    tenantName: string;
    points: string;
  }[];
}

const QRUserPointsResult = ({
  // points,
  // tenantId,
  // tenantName,
  pointsData,
}: //data,
IQRUserPointsResult) => {
  const history = useHistory();
  const location = useLocation<{code: string}>();
  const user = JSON.parse(location.state.code);
  const [showButton, setShowButton] = useState<boolean>(true);
  const [arePointsAdded, setArePointsAdded] = useState<boolean>(false);

  useEffect(() => {
    if (arePointsAdded) {
      setTimeout(() => {
        history.replace(routes.DASHBOARD);
        history.go(-3);
      }, 1000);
    }
  }, [arePointsAdded]);

  useEffect(() => {
    console.log(pointsData);
    if (!pointsData) history.go(-1);
  }, []);

  return (
    <div className="p-8">
      <div className="font-semibold mb-4">Datos:</div>
      <div>
        <span className="font-semibold mr-2">ID Usuario:</span>
        {user.id}
      </div>

      <div>
        <span className="font-semibold mr-2">Email:</span>
        {user.email}
      </div>

      {/* <div>
        <span className="font-semibold mr-2">Operador:</span>
        {tenantName}
      </div> */}

      <div>
        <span className="font-semibold mr-2">Puntos actuales:</span>
        {user.points}
      </div>

      <div className="mt-8 flex flex-col">
        <span className="font-semibold mr-2 mb-4">Puntos a añadir:</span>
        {/* <span className="font-semibold mr-2 text-torre-pink text-xl text-center mb-4">
          {`${points} puntos`}
        </span> */}
      </div>
      <div className="">
        {pointsData?.map((data, index) => (
          <div
            key={index}
            className={`${
              index !== pointsData.length - 1 ? "border-b border-black" : ""
            } px-4 py-2 flex justify-between`}
          >
            <div className="w-1/2">
              <div className="">{data.tenantName}</div>
            </div>
            <div className="w-1/2 flex ">
              <div className="w-1/2">{`${data.price} €`}</div>
              <div className="text-torre-pink font-semibold w-1/2 text-right">
                {data.points}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="border-t border-black p-4 flex justify-between mb-4">
        <div className="text-lg font-semibold">TOTAL</div>
        <div className="text-torre-pink font-semibold text-xl">
          {`${pointsData?.reduce(
            (acumulador, item) => acumulador + Number(item.points),
            0
          )} puntos`}
        </div>
      </div>

      {!arePointsAdded ? (
        <div className="flex justify-center">
          <button
            className={`bg-torre-pink px-4 py-2 text-white rounded mt-4 mx-auto text-center ${
              !showButton ? "hidden" : ""
            } `}
            onClick={() => {
              setShowButton(false);
              addLoyaltyPoints(
                Number(user.id),
                // Number(tenantId),
                // Number(points),
                pointsData
              )
                .then(() => setArePointsAdded(true))
                .catch((err) => console.log(err));
            }}
          >
            Añadir puntos
          </button>
        </div>
      ) : (
        <div className="absolute inset-0 bg-black bg-opacity-75 flex items-center justify-center">
          <button className="bg-torre-pink px-4 py-2 text-white rounded mt-4 mx-auto text-center">
            Puntos añadidos
          </button>
        </div>
      )}
    </div>
  );
};

export default QRUserPointsResult;
