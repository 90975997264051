import React from "react";
import {useHistory} from "react-router-dom";

interface TitleBarProps {
  title: string;
  noBack: boolean;
}

const TitleBar: React.FC<TitleBarProps> = ({title, noBack}) => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="text-center uppercase bg-white h-16 relative flex items-center justify-center">
      {!noBack && (
        <svg
          className="absolute top-1/2 ml-2 h-8 left-0"
          onClick={goBack}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M15 19l-7-7 7-7"></path>
        </svg>
      )}

      <h1 className="md:text-2xl text-center">{title}</h1>
    </div>
  );
};

export default TitleBar;
