import React from "react";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import {useEvents} from "../api/events";
import EventList from "../components/EventList";
import EventSheet from "../components/EventSheet";
import {routes} from "../routes";

const Events = () => {
  let {path} = useRouteMatch();
  const {data: events} = useEvents();

  return (
    <>
      <Router>
        <Switch>
          <Route exact path={path}>
            {events && <EventList events={events.res} />}
          </Route>

          <Route path={`${path}/:id`}>
            <EventSheet />
          </Route>
        </Switch>
      </Router>

      <Link
        to={`${routes.DASHBOARD}/${routes.SCANNER}`}
        className="fixed bottom-0 left-0 right-0 w-full p-5 md:p-8 flex justify-center items-center uppercase md:text-xl font-black text-center bg-black text-white"
      >
        Escaner QR
        <svg className="ml-3 fill-current h-6 md:h-8" viewBox="0 0 512 512">
          <path d="M0 0v170h170V0H0zm130 130H40V40h90v90z" />
          <path d="M65 65h40v40H65zM342 0v170h170V0H342zm130 130h-90V40h90v90z" />
          <path d="M407 65h40v40h-40zM0 342v170h170V342H0zm130 130H40v-90h90v90z" />
          <path d="M65 407h40v40H65zM40 197h40v40H40zM120 277v-40H80v40h39v40h40v-40zM280 77h40v40h-40zM200 40h40v77h-40zM240 0h40v40h-40zM240 117v40h-40v40h80v-80zM280 355v-39h-40v-79h-40v80h40v39h40v39h80v-40z" />
          <path d="M280 197h40v80h-40zM472 236v-39h-73v40h-39v40h40v39h112v-80h-40zm0 40h-72v-39h72v39zM472 355h40v80h-40zM320 277h40v40h-40zM360 395h40v40h-40zM400 355h40v40h-40zM400 435v77h40v-37h32v-40zM200 356h40v76h-40zM320 472v-40h-80v80h40v-40h39v40h40v-40zM120 197h80v40h-80zM0 237h40v80H0z" />
        </svg>
      </Link>
    </>
  );
};

export default Events;
