import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEventReservations } from "../api/eventReservations";
import { useEvent } from "../api/events";
import TitleLayout from "./TitleLayout";

const EventSheet = () => {
  const { id } = useParams();
  const { data: event } = useEvent(id);

  const itemsPerPage = 25;
  const [page, setPage] = useState(1);
  const { data: eventReservations } = useEventReservations(
    event,
    itemsPerPage,
    page
  );

  return event ? (
    <TitleLayout title={event.name}>
      <div className="pb-24">
        <div className="bg-white rounded-md">
          <img
            className="w-full h-64 object-cover"
            src={event.squareBanner}
            alt={event.name}
          />

          <div className="px-6 py-4 flex justify-between">
            <p>
              Capacidad: <span className="font-black">{event.capacity}</span>
            </p>
            <p>
              Reservas: <span className="font-black">{event.reservations}</span>
            </p>
          </div>
        </div>

        {eventReservations && (
          <>
            <ul className="p-6 grid grid-cols-1 md:grid-cols-2 gap-4">
              {eventReservations.res.map((r) => (
                <li
                  className="p-3 flex justify-between rounded bg-white"
                  key={r.id}
                >
                  <p>{`${r.owner.name} ${r.owner.lastName}`}</p>
                  <p>
                    {r.used ? (
                      <svg
                        viewBox="0 0 20 20"
                        className="w-6 h-6 text-green-500 fill-current"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg viewBox="0 0 20 20" className="w-6 h-6 fill-current">
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                  </p>
                </li>
              ))}
            </ul>

            <div className="mt-4 md:mb-6 px-6 flex justify-between items-center">
              <button
                disabled={page === 1}
                className={`px-3 py-2 text-sm text-white ${
                  page === 1 ? "bg-gray-300" : "bg-black"
                }`}
                onClick={() => setPage((p) => p - 1)}
              >
                Anterior
              </button>

              <div className="text-gray-700 text-sm">{`${page}/${Math.ceil(
                eventReservations.meta.total_count / itemsPerPage
              )}`}</div>

              <button
                className="px-3 py-2 text-sm text-white bg-black"
                onClick={() => setPage((p) => p + 1)}
              >
                Siguiente
              </button>
            </div>
          </>
        )}
      </div>
    </TitleLayout>
  ) : null;
};

export default EventSheet;
