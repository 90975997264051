import {useQuery} from "react-query";
import {apiBase, multiDataProcessor} from "./utils";

export interface Tenant {
  id: number;
  name: string;
}

export const tenantFields = ["id", "name"].join(",");

function processTenant(data: any): Tenant {
  return {
    id: data.id,
    name: data.name,
  };
}

export function useTenants() {
  const endpoint = `${apiBase}/items/tenants?lang=es&fields=${tenantFields}`;
  return useQuery(["tenants"], () =>
    multiDataProcessor(endpoint, processTenant)
  );
}
