import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  confirmEventReservation,
  useEventReservationByCode,
} from "../api/eventReservations";
import Button from "./Button";

const QRResult = () => {
  const history = useHistory();
  const location = useLocation<{ code: string }>();
  const eventCode = location.state.code;
  const { data: eventReservation } = useEventReservationByCode(eventCode!);

  if (!eventReservation) return null;

  return eventReservation.res.length > 0 ? (
    <div className="p-4 md:w-1/2 m-auto">
      <h2 className="text-center text-xl md:text-2xl uppercase font-black">
        Reserva de evento
      </h2>

      <img
        className="mt-6 w-full h-64 object-cover"
        src={eventReservation.res[0].event.squareBanner}
        alt={eventReservation.res[0].event.name}
      />

      <div className="mt-4">
        <p>
          <span className="font-bold">Evento:</span>{" "}
          {eventReservation.res[0].event.name}
        </p>
        <p className="mt-1">
          <span className="font-bold">Titular:</span>{" "}
          {`${eventReservation.res[0].owner.name} ${eventReservation.res[0].owner.lastName}`}
        </p>
      </div>

      <div className="mt-6">
        {eventReservation.res[0].used ? (
          <>
            <h3 className="mt-6 text-2xl text-center">Ticket ya confirmado</h3>

            <div className="mt-4">
              <Button
                filled
                type="submit"
                className="w-full mt-2"
                onClick={history.goBack}
              >
                Atras
              </Button>
            </div>
          </>
        ) : (
          <>
            <Button
              filled
              pink
              type="submit"
              className="w-full"
              onClick={async () => {
                try {
                  confirmEventReservation(eventReservation.res[0].id);
                  history.goBack();
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              Confirmar
            </Button>

            <Button
              filled
              type="submit"
              className="w-full mt-2"
              onClick={history.goBack}
            >
              Cancelar
            </Button>
          </>
        )}
      </div>
    </div>
  ) : (
    <div className="p-4 md:w-1/2 m-auto">
      <div className="mt-6 text-2xl text-center">Ticket no encontrado</div>

      <div className="mt-4">
        <Button
          filled
          type="submit"
          className="w-full mt-2"
          onClick={history.goBack}
        >
          Atras
        </Button>
      </div>
    </div>
  );
};

export default QRResult;
