import React from "react";

interface BirthdayErrorProps {
  msg: string[];
  days?: number;
}

const BirthdayError: React.FC<BirthdayErrorProps> = ({msg, days}) => {
  return (
    <div className="p-6">
      {!days ? (
        <div className="text-center">{msg}</div>
      ) : (
        <>
          {msg.map((m, i) => (
            <div key={i} className="p-2 text-center">
              {m}
            </div>
          ))}

          <div className="text-center">
            {days < 0 ? "Faltan" : "Hace"} {Math.abs(days)} días
          </div>
        </>
      )}
    </div>
  );
};

export default BirthdayError;
