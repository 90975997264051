import { ErrorMessage, useField } from "formik";
import React from "react";

interface IProfileTextField {
  label: string;
  name: string;
  type: string;
}

const ProfileTextField: React.FC<IProfileTextField> = ({
  label,
  name,
  type,
  ...props
}) => {
  const [field, meta] = useField({
    name,
    type,
    ...props,
  });

  return (
    <>
      <label className="uppercase text-2xs flex self-start" htmlFor={name}>
        {label}
      </label>

      <input
        type={type}
        className={`mt-4 border-b-2 border-black bg-transparent w-full ${
          meta.touched && meta.error ? "border-torre-pink " : "mb-6"
        }`}
        {...field}
        {...props}
      />

      {meta.touched && meta.error ? (
        <p className="text-xs mt-2 mb-4 text-torre-pink self-start">
          <ErrorMessage name={name} />
        </p>
      ) : null}
    </>
  );
};

export default ProfileTextField;
