import React, {ChangeEvent, useState} from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import QRProductResult from "../components/QRProductResult";
import QRScanner from "../components/QRScanner";
import QRUserPointsResult from "../components/QRUserPointsResult";
import TitleLayout from "../components/TitleLayout";
import {routes} from "../routes";
import {TorreEvent, useLoyaltyEvents} from "../api/events";

const ProductScanner = () => {
  let {path} = useRouteMatch();
  const {data: events} = useLoyaltyEvents();

  const [formData, setFormData] = useState<{
    eventId: string;
    eventName: string;
  }>({
    eventId: "0",
    eventName: "",
  });

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    const eventName = event.target.selectedOptions[0].textContent || "";
    setFormData((prevState) => ({
      ...prevState,
      eventId: value,
      eventName,
    }));
  };

  return (
    <TitleLayout title="Escaner QR">
      <Router>
        <Switch>
          <Route exact path={path}>
            <div className="p-4">
              <form>
                <select
                  className="w-full mb-4 px-2 py-4"
                  defaultValue={0}
                  onChange={handleSelectChange}
                  name="eventId"
                >
                  <option value="0">Selecciona un evento</option>
                  {events?.res
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((event: TorreEvent) => (
                      <option value={event.id} key={event.id}>
                        {event.name}
                      </option>
                    ))}
                </select>
              </form>
            </div>
            <QRScanner type="product" />
          </Route>

          <Route path={`${path}/${routes.PRODUCT_SCANNER_RESULT}`}>
            <QRProductResult
              eventID={formData.eventId}
              eventName={formData.eventName}
            />
          </Route>
        </Switch>
      </Router>
    </TitleLayout>
  );
};

export default ProductScanner;
