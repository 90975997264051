import React from "react";
import TitleBar from "./TitleBar";
import logo from "../assets/logo.png";
interface TitleLayoutProps {
  title: string;
  banner?: string;
  noBack?: boolean;
  showLogo?: boolean;
}

const TitleLayout: React.FC<TitleLayoutProps> = ({
  title,
  noBack = false,
  children,
  showLogo = false,
}) => {
  return (
    <>
      <TitleBar title={title} noBack={noBack} />
      <div className="flex-grow overflow-y-auto">
        {showLogo && (
          <img
            className="w-24 mx-auto mt-8"
            src={logo}
            alt="La Torre Outlet Zaragoza"
          />
        )}

        {children}
      </div>
    </>
  );
};

export default TitleLayout;
