import useStore from "../store";
import {dataProcessor, multiDataProcessor} from "../api/utils";

const apiBase = "https://cms.latorreoutletzaragoza.com/_";
//const apiBase = "http://localhost:1338/_";

export interface IUserBirtydayData {
  id: string;
  birthdate: string;
  lastBirtydayPresent: string;
}

function processUserBirthdayData(data: any): IUserBirtydayData {
  return {
    id: data.id,
    birthdate: data.birthdate,
    lastBirtydayPresent: data.last_birthday_present || "",
  };
}

const fields = ["id", "birthdate", "last_birthday_present"].join(",");

export async function checkBirthday(
  email: string
): Promise<IUserBirtydayData[]> {
  const endPoint = `${apiBase}/users?filter[email][eq]=${email}&fields=${fields}`;
  const birthdayData = await multiDataProcessor(
    endPoint,
    processUserBirthdayData
  );
  return birthdayData.res;
}

export async function updateLastBirthdayPresent(
  id: string,
  last_birthday_present: string
): Promise<IUserBirtydayData[]> {
  const token = useStore.getState().token;
  const endpoint = `${apiBase}/users/${id}`;
  const res = await fetch(endpoint, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      last_birthday_present,
    }),
  });
  const {data} = await res.json();
  return data;
}

export async function updateUserPoints(
  id: string,
  points: number
): Promise<IUserBirtydayData[]> {
  try {
    const token = useStore.getState().token;
    const endpoint = `${apiBase}/users/${id}`;

    const userRes = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    });

    const userData = await userRes.json();

    //console.log(userData.data.points);
    //console.log(points);

    const res = await fetch(endpoint, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
      body: JSON.stringify({
        points: points + userData.data.points,
      }),
    });
    const {data} = await res.json();
    return data;
  } catch (err) {
    throw new Error();
  }
}

export async function addLoyaltyPoints(
  userID: number,
  pointsData: {
    price: string;
    tenantId: string;
    tenantName: string;
    points: string;
  }[]
  // tenantID: number,
  // points: number
) {
  try {
    const token = useStore.getState().token;
    const endpoint = `${apiBase}/items/loyalty_points`;

    const updatedData = pointsData.map((item) => ({
      user: userID,
      tenant: item.tenantId,
      points: item.points,
    }));

    //console.log(updatedData);

    const res = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
      body: JSON.stringify(updatedData),
    });
    const {data} = await res.json();
    //console.log(data);
    return data;
  } catch (err) {
    throw new Error();
  }
}
