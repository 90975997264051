import {useQuery} from "react-query";
import {apiBase, dataProcessor, multiDataProcessor} from "./utils";

export interface TorreEvent {
  id: number;
  name: string;
  from: string;
  to: string;
  description: string;
  squareBanner: string;
  capacity: number;
  reservations: number;
}

const eventFields = [
  "id",
  "from",
  "to",
  "translations.name",
  "translations.description",
  "square_banner.data",
  "capacity",
  "reservations",
].join(",");

const currentEventsFilter = `filter[capacity][gt]=0&filter[from][lte]=now&filter[to][gte]=now`;

function processEvent(data: any): TorreEvent {
  return {
    id: data.id,
    from: data.from,
    to: data.to,
    name: data.translations[0].name,
    description: data.translations[0].description,
    capacity: data.capacity,
    reservations: data.reservations,
    squareBanner: data.square_banner.data.full_url,
  };
}

export function useEvent(id: string) {
  const endpoint = `${apiBase}/items/events/${id}?lang=es&fields=${eventFields}`;
  return useQuery(["event", id], () => dataProcessor(endpoint, processEvent));
}

export function useEvents() {
  let endpoint = `${apiBase}/items/events?lang=es&${currentEventsFilter}&fields=${eventFields}`;
  return useQuery(["events"], () => multiDataProcessor(endpoint, processEvent));
}

export function useLoyaltyEvents() {
  const currentEventsFilter = `filter[from][lte]=now&filter[to][gte]=now`;
  let endpoint = `${apiBase}/items/events?lang=es&${currentEventsFilter}&fields=${eventFields}`;
  return useQuery(["events"], () => multiDataProcessor(endpoint, processEvent));
}
